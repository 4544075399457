/* styles/globals.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/DMSans-Regular.ttf") format("truetype");
}

.fadein {
  animation-name: fadein;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/DMSans-Bold.ttf") format("truetype");
}

/* App.css */

body {
  font-family: "DM Sans";
  background-color: black;
}

.MuiPopover-paper {
  box-shadow: none !important;
  border: 2px solid #a8b9bf !important;
}

/* 
fix to mobile tooltips display
*/
.react-tooltip {
  max-width: min(calc(100% - 10px), 500px) !important;
  border-radius: 15px !important;
  background: #172036 !important;
  font-size: 14px !important;
  color: white !important;
  padding: 15px !important;
}

@keyframes callAttention {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}

.attention-icon {
  display: inline-block;
  font-size: 24px;
  animation: callAttention 1s infinite;
}

.MuiTooltip-tooltip {
  border-radius: 15px !important;
  background: #172036 !important;
  padding: 15px;
  font-size: 16px;
  line-height: 28px;
}

[role="dialog"] {
  /* border-radius: 20px !important; */
  font-size: 14px !important;
  font-family: "DM Sans";
  padding: 5px !important;
  backdrop-filter: blur(10px);
  background-color: #fffffffb;
}

[role="dialog"] .MuiDialogTitle-root button {
  zoom: 2;
}

[role="dialog"] .MuiDialogTitle-root svg {
  color: white;
  background-color: #172036;
  border-radius: 50%;
}

.bm-burger-button {
  border: 2px solid #d5dbe9;
  border-radius: 15px;
  padding: 2px;
  z-index: 1000;
  position: fixed;
  width: 47px !important;
  height: 45px !important;
  right: 17px !important;
  top: 14px !important;
  margin-left: 10px !important;
}

@media (max-width: 768px) {
  .hide-bg-image-on-mobile {
    background-image: none !important;
    background-color: transparent !important;
  }
}

.gradient-border-button {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: white;
}

.gradient-border-button::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: radial-gradient(
    85% 85% at 26.54% 7.31%,
    #80e8ff 0%,
    #9fc1ff 25%,
    #cdcdff 50%,
    rgba(255, 157, 192, 0.760777) 75%,
    rgba(255, 170, 170, 0.587546) 100%
  );
  border-radius: 50%;
  z-index: -1;
}

/* styles.css */
#outer-container {
  max-width: 1800px;
  width: 100vw;
  margin-top: -92px;
  min-height: 100vh;
  padding-top: 170px;
  /* overflow: scroll; */

  /* padding-bottom: 92px;
  margin-bottom: -92px; */
  position: relative;
  margin: 0 auto;
  background-color: white;
}

@media (max-width: 1024px) {
  #outer-container {
    width: 105vw;
  }
}

header {
  position: sticky;
  top: 0;
  width: 100%;
  max-width: 1800px;
  height: 170px;
  z-index: 1;
}

.header-wrapper {
  max-width: 1800px;
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
}

@media (max-width: 1280px) {
  .header-wrapper {
    padding: 10px;
  }

  .hide-on-mobile-tablet {
    display: none;
  }
}

@media (max-width: 1024px) {
  .hide-on-mobile {
    display: none;
  }
}

footer {
  background-color: rgb(234 245 255 / 63%);
  z-index: 1;
  backdrop-filter: blur(10px);
}

[id="country-select-demo-listbox"] li {
  height: 70px;
}

@media (min-width: 1024px) {
  .MuiSnackbar-root {
    max-width: 50%;
  }
}

[id="recording-tooltip"] {
  max-width: 400px !important;
  z-index: 900 !important;
}
