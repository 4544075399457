.Header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 6rem;
  margin-right: 6rem;
  margin-top: 2rem;
  text-shadow: 1px 2px 7px white;
}

.HeaderMenu {
  min-width: calc(100% - 200px - 330px);
  margin-left: 40px;
  padding-top: 40px;
  color: black;
  a {
    text-transform: uppercase;
    font-family: "DM Sans", sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin-right: 20px;
  }
}

.HeaderMenuIcon {
  margin-left: 10px;
  width: 48px;
  height: 48px;
  border-width: 2px;
  border-radius: 15px;
  border-style: solid;
  border-color: rgb(224, 224, 241);
  padding: 12px;
  cursor: pointer;
  background-color: white;
}

.HeaderButtons {
  display: flex;
  width: 250px;
}

.ProductHunt {
  padding-top: 15px;
}

@media (min-width: 1024px) and (max-width: 1500px) {
  .Header {
    margin-left: 20px;
    margin-right: 0px;
    margin-top: 1rem;
  }
}

@media (max-width: 1024px) {
  .Header {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 1rem;
  }

  .HeaderButtons {
    display: flex;
    width: auto;
  }
}
