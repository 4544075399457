.message,
.button {
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  line-height: 2rem;
}

.button {
  font-family: "DM Sans", sans-serif;
  color: white;
}
