.CountryAndLanguageSelectorWrapper {
  width: 300px;
}

.country-selector {
  padding: 0;
  position: relative;
  z-index: 1;
  border-radius: 20px;
  height: 52px;
  backdrop-filter: blur(2px);
  font-family: "DM Sans", sans-serif;
  border: black;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  margin-bottom: 20px;
}

.country-selector-with-background {
  background-color: #263049;
  color: white !important;
  input::placeholder {
    color: white !important;
  }
}

.country-selector {
  img {
    border-radius: 5px;
    margin-top: -10px;
    position: relative;
  }

  button {
    margin-top: -10px !important;
  }

  svg {
    background: #b7c6eb;
  }

  input,
  fieldset {
    border: none !important;
    background-color: transparent !important;
    padding-top: 0 !important;
    color: black;
  }
}
