.Wrapper {
  padding: 100px;
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  width: 100vw;
  font-family: "DM Sans", sans-serif;
  min-height: 100vh;
  height: calc(100% + 100px);
  background: #172036;
  background-image: url(/assets/footer-back.webp);
  background-repeat: no-repeat;
  background-position: 0 -32px;
  background-size: cover;

  a {
    color: #fff;
    margin-right: 20px;
  }
}

.Row {
  display: flex;
}

.LanguageAndClose {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.Row {
  justify-content: space-between;
  margin-top: 50px;
}

.RowToCol {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: space-between;

  div {
    display: inline-flex;
  }
}

.Close {
  margin-left: 10px;
  width: 52px;
  height: 52px;
  border: black;
  border-width: 2px;
  border-style: solid;

  background: #263049;
  border-radius: 15px;
}

.Slogan {
  font-size: 100px;
  font-family: "DM Sans", sans-serif;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ItemBox {
  padding: 25px;
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.08);
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 30px;
}

.ItemWrapper {
  cursor: pointer;
  color: #fff;
  width: 300px;
  margin-top: 20px;
  transition: transform 0.15s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
}

.ItemTitle {
  margin-top: 10px;
  font-size: 32px;
}

.ItemSubTitle {
  font-size: 16px;
  margin-top: 10px;
}

.Items {
  display: inline-flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  align-items: flex-start;
}

@media (min-width: 1024px) and (max-width: 1500px) {
  .Wrapper {
    padding: 20px;
    overflow-x: hidden;
  }

  .Slogan {
    font-size: 50px;
  }

  .ItemBox {
    width: 150px;
    height: 150px;
  }

  .ItemWrapper {
    min-width: 250px;
  }

  .ItemTitle {
    font-size: 24px;
  }

  .Row {
    margin-top: 20px;
  }
}

@media (max-width: 1024px) {
  .Wrapper {
    padding: 20px;

    overflow-x: hidden;
  }

  .Slogan {
    font-size: 40px;
  }

  .ItemBox {
    width: 100px;
    height: 100px;
  }

  .ItemTitle {
    font-size: 24px;
  }

  .Row {
    margin-top: 20px;
    flex-direction: column;

    div {
      margin-bottom: 10px;
    }
  }

  .ItemWrapper {
    min-width: 0;
    width: 150px;
    margin-top: 10px;

    overflow: hidden;
  }
}
