.SocialIcons {
  display: flex;

  img {
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.3);
    }
  }

  margin-left: -10px;
  img {
    margin: 10px;
    cursor: pointer;
  }
}

.Title {
  color: white;
}
